<template>
  <v-container fluid>
    <v-layout row wrap align-center>
      <v-flex xs12 sm12 md12>
        <v-card>
          <v-layout row>
            <v-card-text class="title" style="margin-top: 14px"
              >MarkSheet Lot Print</v-card-text
            >
            <v-divider class="my-2" vertical></v-divider>
            <v-spacer />
          </v-layout>
        </v-card>
        <v-card class="px-3">
          <v-layout row wrap>
            <v-flex xs12 sm12>
              <div>
                <v-tabs
                  v-model="nextCMTtab"
                  color="#0097A7"
                  dark
                  slider-color="yellow"
                  style="text-align: center"
                >
                  <v-tab>College & Subject Wise</v-tab>
                  <v-tab>Serial wise</v-tab>
                  <v-tab>Exam roll/ Reg wise</v-tab>
                  <v-tab-item>
                    <v-card flat>
                      <v-layout row wrap style="margin-top: 10px">
                        <v-flex xs12 sm6 style="padding: 10px">
                          <v-text-field
                            v-model="crt.col_code_from"
                            label="From College"
                            prepend-inner-icon="account_balance"
                            outline
                          >
                          </v-text-field>
                        </v-flex>
                        <v-flex xs12 sm6 style="padding: 10px">
                          <v-text-field
                            v-model="crt.col_code_to"
                            label="To college"
                            prepend-inner-icon="account_balance"
                            outline
                          >
                          </v-text-field>
                        </v-flex>
                      </v-layout>
                      <v-layout row wrap>
                        <v-flex
                          xs12
                          sm6
                          style="padding: 10px; margin-top: -16px"
                        >
                          <v-text-field
                            v-model="crt.sub_code_from"
                            label="From Subject"
                            prepend-inner-icon="file_copy"
                          >
                          </v-text-field>
                        </v-flex>
                        <v-flex
                          xs12
                          sm6
                          style="padding: 10px; margin-top: -16px"
                        >
                          <v-text-field
                            v-model="crt.sub_code_to"
                            label="To Subject"
                            prepend-inner-icon="file_copy"
                          >
                          </v-text-field>
                        </v-flex>
                      </v-layout>

                      <v-layout
                        row
                        wrap
                        style="
                          text-align: right;
                          margin-top: -10px;
                          margin-bottom: 10px;
                        "
                      >
                        <v-flex xs12 sm12>
                          <v-btn
                            color="primary"
                            @click="getMarkSheetRegister((is_count = '1'))"
                            >Search</v-btn
                          >
                        </v-flex>
                      </v-layout>
                      <v-layout row wrap style="text-align: left" v-if="this.valueOfTotal !== ''">
                        <v-flex xs12 sm12>
                          <div
                            class="px-3"
                            style="
                              margin-top: -50px;
                              padding-bottom: 10px;
                              font-size: x-large;
                            "
                          >
                            <b>Total Printable MarkSheet:</b>&nbsp;
                            <span style="color: red">
                              <b> {{ valueOfTotal }}</b></span
                            >
                          </div>
                        </v-flex>
                      </v-layout>
                      <v-layout
                        row
                        wrap
                        style="text-align: center"
                        v-if="this.valueOfTotal !== ''"
                      >
                        <v-flex
                          xs12
                          sm4
                          style="margin-left: 10%; padding-top: 20px"
                        >
                          <v-text-field
                            label="MarkSheet Print Serial Start From"
                            v-model="sumOfc"
                            prepend-inner-icon="file_copy"
                            @input="totalCprintCol()"
                            type="number"
                          >
                          </v-text-field>
                        </v-flex>
                        <v-flex
                          xs12
                          sm6
                          style="margin-top: 30px; font-size: x-large"
                        >
                          <b>Last Serial:</b>&nbsp;
                          <span style="color: red"
                            ><b>{{ totalSum }}</b></span
                          >
                        </v-flex>
                      </v-layout>
                      <v-layout row wrap style="text-align: center">
                        <v-flex
                          xs12
                          sm12
                          style="padding-top: 10px; padding-bottom: 10px"
                        >
                          <v-btn
                            @click="getMarkSheetRegister()"
                            color="primary"
                            :disabled="this.totalSum == ''"
                            ><v-icon color="light" style="padding-right: 5px"
                              >print</v-icon
                            >
                            Print</v-btn
                          >
                        </v-flex>
                      </v-layout>
                      <v-layout row wrap v-if="this.totalSum !== ''">
                        <v-flex
                          xs12
                          sm12
                          style="padding: 10px; margin-top: -16px"
                        >
                          <iframe
                            :src="pdfUrl"
                            ref="pdfIframe"
                            id="iframe"
                            type="application/pdf"
                            style="width: 100%; height: 800px"
                          ></iframe>
                        </v-flex>
                      </v-layout>
                    </v-card>
                  </v-tab-item>

                  <!-- c-peint with comSL and com_endSL -->
                  <v-tab-item>
                    <v-card flat>
                      <v-layout row wrap style="margin-top: 0px">
                        <v-flex xs12 sm6 style="padding: 10px">
                          <v-text-field
                            label="From Serial"
                            prepend-inner-icon="format_list_numbered_rtl"
                            v-model="com_start"
                          >
                          </v-text-field>
                        </v-flex>
                        <v-flex xs12 sm6 style="padding: 10px">
                          <v-text-field
                            label="To Serial"
                            prepend-inner-icon="format_list_numbered_rtl"
                            v-model="com_end"
                          >
                          </v-text-field>
                        </v-flex>
                      </v-layout>

                      <v-layout
                        row
                        wrap
                        style="
                          text-align: right;
                          margin-top: -10px;
                          margin-bottom: 10px;
                        "
                      >
                        <v-flex xs12 sm12>
                          <v-btn
                            color="primary"
                            @click="getMarkSheetwithComSL((is_count = '1'))"
                            >Search</v-btn
                          >
                        </v-flex>
                      </v-layout>
                      <v-layout row wrap style="text-align: center">
                        <v-flex xs12 sm12>
                          <v-card
                            class="px-3"
                            style="
                              margin-top: -10px;
                              padding-bottom: 10px;
                              font-size: x-large;
                            "
                          >
                            <b>Total Printable MarkSheet:</b>
                            <span style="color: red"
                              ><b> {{ valueOfTotalSl }}</b></span
                            >
                          </v-card>
                        </v-flex>
                      </v-layout>
                      <v-layout row wrap style="text-align: center">
                        <v-flex
                          xs12
                          sm4
                          style="margin-left: 10%; padding-top: 20px"
                        >
                          <v-text-field
                            label="MarkSheet Print Serial Start From"
                            v-model="sumOfsl"
                            prepend-inner-icon="file_copy"
                            @input="totalCprintSl()"
                            type="number"
                          >
                          </v-text-field>
                        </v-flex>
                        <v-flex
                          xs12
                          sm6
                          style="margin-top: 30px; font-size: x-large"
                        >
                          <b>Last Serial:</b>
                          <span style="color: red"
                            ><b>{{ totalSumsl }}</b></span
                          >
                        </v-flex>
                      </v-layout>
                      <v-layout row wrap style="text-align: center">
                        <v-flex
                          xs12
                          sm12
                          style="padding-top: 10px; padding-bottom: 10px"
                        >
                          <v-btn
                            color="primary"
                            :disabled="this.totalSumsl == ''"
                            @click="getMarkSheetwithComSL()"
                            ><v-icon color="light" style="padding-right: 5px"
                              >print</v-icon
                            >
                            Print</v-btn
                          >
                        </v-flex>
                      </v-layout>
                      <v-layout row wrap v-if="this.totalSumsl !== ''">
                        <v-flex
                          xs12
                          sm12
                          style="padding: 10px; margin-top: -16px"
                        >
                          <iframe
                            :src="pdfUrlSl"
                            ref="pdfIframe"
                            id="iframe"
                            type="application/pdf"
                            style="width: 100%; height: 800px"
                          ></iframe>
                        </v-flex>
                      </v-layout>
                    </v-card>
                  </v-tab-item>

                  <!-- c-print with roll and reg -->
                  <v-tab-item>
                    <v-card flat>
                      <v-layout row wrap style="margin-top: 30px">
                        <v-flex xs12 sm5 style="margin-left: 5%">
                          <v-text-field
                            label="Exam Roll"
                            prepend-inner-icon="numbers"
                            v-model="exam_roll_no"
                            outline
                          >
                          </v-text-field>
                        </v-flex>
                        <v-flex xs12 sm5 style="margin-left: 5%">
                          <v-text-field
                            label="Registration"
                            prepend-inner-icon="numbers"
                            v-model="reg_no"
                            outline
                          >
                          </v-text-field>
                        </v-flex>
                      </v-layout>

                      <v-layout row wrap style="text-align: center">
                        <v-flex
                          xs12
                          sm12
                          style="padding-top: 10px; padding-bottom: 10px"
                        >
                          <v-btn
                            color="primary"
                            @click="getMarkSheetwithRollReg()"
                            ><v-icon color="light" style="padding-right: 5px"
                              >print</v-icon
                            >
                            Print</v-btn
                          >
                        </v-flex>
                      </v-layout>
                      <v-layout row wrap>
                        <v-flex
                          xs12
                          sm12
                          style="padding: 10px; margin-top: -16px"
                        >
                          <iframe
                            :src="pdfUrlRoll"
                            ref="pdfIframe"
                            id="iframe"
                            type="application/pdf"
                            style="width: 100%; height: 800px"
                          ></iframe>
                        </v-flex>
                      </v-layout>
                    </v-card>
                  </v-tab-item>
                </v-tabs>
              </div>
            </v-flex>
          </v-layout>

          <v-card style="margin-top: 20px">
            <v-layout row>
              <v-card-text class="title">C/M/T Register</v-card-text>
              <v-divider class="my-2" vertical></v-divider>
              <v-spacer />
            </v-layout>
          </v-card>

          <v-layout row wrap align-center>
            <v-flex xs12 sm12 md12>
              <template>
                <v-data-table
                  :headers="headers"
                  :items="desserts"
                  class="elevation-1"
                >
                  <template v-slot:items="props">
                    <td>{{ props.item.name }}</td>
                    <td class="text-xs-right">{{ props.item.calories }}</td>
                    <td class="text-xs-right">{{ props.item.fat }}</td>
                    <td class="text-xs-right">{{ props.item.carbs }}</td>
                    <td class="text-xs-right">{{ props.item.protein }}</td>
                    <td class="text-xs-right">{{ props.item.iron }}</td>
                  </template>
                </v-data-table>
              </template>
            </v-flex>
          </v-layout>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import common_login_getters from "@/mixin/common_login_getters";
import commonExamYear from "../../mixin/exam_year_watch_mixin";
import commonGet from "../../mixin/common_getters_mixin";

export default {
  mixins: [common_login_getters, commonGet, commonExamYear],
  data() {
    return {
      nextCMTtab: 0, // Initialize the active tab to 0 (MarkSheet)
      headers: [
        {
          text: "Dessert (100g serving)",
          align: "left",
          sortable: false,
          value: "name",
        },
        { text: "Calories", value: "calories" },
        { text: "Fat (g)", value: "fat" },
        { text: "Carbs (g)", value: "carbs" },
        { text: "Protein (g)", value: "protein" },
        { text: "Iron (%)", value: "iron" },
      ],
      desserts: [
        {
          name: "Frozen Yogurt",
          calories: 159,
          fat: 6.0,
          carbs: 24,
          protein: 4.0,
          iron: "1%",
        },
        {
          name: "Ice cream sandwich",
          calories: 237,
          fat: 9.0,
          carbs: 37,
          protein: 4.3,
          iron: "1%",
        },
      ],
      text1: "Exam roll/ Reg wise",
      text2: "College & Subject Wise",
      text3: "Serial wise",

      valueOfTotal: "",
      valueOfTotalSl: "",
      valueOfTotalReg: "",

      sumOfc: "",
      sumOfsl: "",

      totalSum: "",
      totalSumsl: "",

      crt: {},

      pdfUrl: "",
      pdfUrlSl: "",
      pdfUrlRoll: "",

      com_start: "",
      com_end: "",

      exam_roll_no: "",
      reg_no: "",
    };
  },

  methods: {
    totalCprintCol() {
      const sumOfcNumber = parseFloat(this.sumOfc);
      if (!isNaN(sumOfcNumber)) {
        this.totalSum = sumOfcNumber + this.valueOfTotal;
      } else {
        this.totalSum = "";
      }
      console.log("Tanvir, This is sum data", this.totalSum);
    },

    totalCprintSl() {
      const sumOfslNumber = parseFloat(this.sumOfsl);
      if (!isNaN(sumOfslNumber)) {
        this.totalSumsl = sumOfslNumber + this.valueOfTotalSl;
      } else {
        this.totalSumsl = "......";
      }
      console.log("Tanvir, This is sum data", this.totalSumsl);
    },

    getMarkSheetRegister(is_count) {
      this.loading = true;
      let param = {};
      param.exam_code = this.getLoggedInExam.code;
      param.year = this.getLoggedInYear.year;
      param.exam_initiate_id = this.getExamInitiateId;
      param.college_code_start = this.crt.col_code_from;
      param.college_code_end = this.crt.col_code_to;
      param.subject_code_start = this.crt.sub_code_from;
      param.subject_code_end = this.crt.sub_code_to;
      if (is_count) {
        param.is_count = is_count;
      } else {
        param.is_count = "";
      }
      this.$store.dispatch("fetchMarkSheetRegisterData", param).then((data) => {
        if (data.total_count) {
          this.valueOfTotal = data.total_count;
          console.log("Tanvir this is total count", this.valueOfTotal);
        } else {
          const iframe = this.$refs.pdfIframe;
          this.pdfUrl = data;
          iframe.addEventListener("load", () => {
            const iframeDocument =
              iframe.contentDocument || iframe.contentWindow.document;
            const toolbarViewerRight =
              iframeDocument.getElementById("toolbarViewerRight");
            const downloadButton = iframeDocument.querySelector(
              ".download-button-selector"
            ); // Replace with the actual selector
            if (toolbarViewerRight) {
              toolbarViewerRight.style.display = "none";
            }
          });
        }
        console.log("Tanvir data of CMT", data);
      });
    },

    getMarkSheetwithComSL(is_count) {
      let SlParam = {};
      SlParam.com_start = this.com_start;
      SlParam.com_end = this.com_end;
      SlParam.exam_code = this.getLoggedInExam.code;
      SlParam.year = this.getLoggedInYear.year;
      SlParam.exam_initiate_id = this.getExamInitiateId;
      if (is_count) {
        SlParam.is_count = is_count;
      } else {
        SlParam.is_count = "";
      }
      this.$store
        .dispatch("fetchMarkSheetRegisterData", SlParam)
        .then((data) => {
          if (data.total_count) {
            this.valueOfTotalSl = data.total_count;
          } else {
            const iframe = this.$refs.pdfIframe;
            this.pdfUrlSl = data;
            iframe.addEventListener("load", () => {
              const iframeDocument =
                iframe.contentDocument || iframe.contentWindow.document;
              const toolbarViewerRight =
                iframeDocument.getElementById("toolbarViewerRight");
              const downloadButton = iframeDocument.querySelector(
                ".download-button-selector"
              ); // Replace with the actual selector
              if (toolbarViewerRight) {
                toolbarViewerRight.style.display = "none";
              }
            });
          }
        });
    },

    getMarkSheetwithRollReg() {
      let rollParam = {};
      rollParam.exam_code = this.getLoggedInExam.code;
      rollParam.year = this.getLoggedInYear.year;
      rollParam.exam_initiate_id = this.getExamInitiateId;
      rollParam.exam_roll_no = this.exam_roll_no;
      rollParam.reg_no = this.reg_no;
      console.log("Tanvir this is C-print with roll/reg", rollParam);
      this.$store
        .dispatch("fetchMarkSheetRegisterData", rollParam)
        .then((data) => {
          const iframe = this.$refs.pdfIframe;
          this.pdfUrlRoll = data;
          iframe.addEventListener("load", () => {
            const iframeDocument =
              iframe.contentDocument || iframe.contentWindow.document;
            const toolbarViewerRight =
              iframeDocument.getElementById("toolbarViewerRight");
            const downloadButton = iframeDocument.querySelector(
              ".download-button-selector"
            );
            if (toolbarViewerRight) {
              toolbarViewerRight.style.display = "none";
            }
          });
        });
    },
  },
};
</script>
